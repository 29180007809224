import React from "react";

import PageTitle from "../components/elements/PageTitle";
import Header from "../components/sections/Header";
import CertificationSection from "../components/sections/CertificationSection";
import Footer from "../components/sections/Footer";
import CertificationBlockquoteItem from "../components/elements/CertificationBlockquoteItem";
import CertificationItem from "../components/elements/CertificationItem";

const certificationPage = () => (
  <div className="bg-white">
    <PageTitle pageTitle={"Certification"} description={``} />
    <Header />
    <CertificationSection
      title="Certification project steps"
      description=""
    >
      <CertificationItem 
        title="Documentation and Requirements Analysis"
        text={["During the analysis phase, all requirements were broken down into separate parts in collaboration with the development team. Key requirements and those related to product security from the perspective of potential threats were identified. The necessary technological stack and tools for project implementation were chosen."]}
      />
      <CertificationItem 
        title="Design"
        text={["Based on the analysis, a system design was developed, describing the main components, interaction protocols, and application structures. In addition to functional requirements, the design addresses aspects related to countering potential threats."]}
      />
      <CertificationItem 
        title="Implementation and Testing"
        text={["After designing, the development team implemented everything in code. The main focus during project implementation was on tracing requirements in the code, meaning that the code should only contain functions required to comply with legislation. This required 100% code coverage with tests. The tests were designed to check each legislative requirement, as well as security requirements and requirements from the certification agency. End-to-end tests were also developed to assess system functioning."]}
        imageName="pos_pos.jpg"
      />
      <CertificationItem 
        title="Documentation"
        text={["During product certification, special attention was paid to creating a document system for process management in the client company. Initially, documentation was absent, and some processes were missing. The project's task included both preparing documentation and establishing missing processes. Certification was conducted according to ISO 9001 standards with all associated requirements. The entire package of necessary documents was compiled, and a process management system was developed and implemented in the company. Processes were communicated to the company's employees."]}
        linkPath="/#contacts"
        linkText="Go to contact page"
      />
      </CertificationSection >
    <Footer />
  </div>
);

export default certificationPage;
