import React from "react";
import { ChevronRightIcon } from "@heroicons/react/outline";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { useImageForData } from "../../hooks/useAllImages";

const CertificationItem = ({ title, text, linkPath, linkText, imageName }) => {
  const img = useImageForData(imageName);
  return (
    <div className="max-w-prose mx-auto">
      <h3 className="text-left text-xl mb-4 leading-8 font-bold tracking-tight text-gray-900 sm:text-2xl">
        {title}
      </h3>
      <div className="prose prose-indigo text-gray-500 mx-auto lg:max-w-none">
        {text.map((par) => (
          <p>{par}</p>
        ))}
      </div>
      {linkPath && <Link
        to={linkPath}
        className="mt-10 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-600"
      >
        {linkText}{" "}
        <ChevronRightIcon
          className="flex-shrink-0 w-6 h-6 text-teal-100"
          aria-hidden="true"
        />
      </Link>}
      {imageName && (
        <div className="max-w-2xl mt-8" aria-hidden="true">
          <GatsbyImage
            image={img}
            className="rounded-lg shadow-lg object-cover object-center w-full max-h-80 h-full"
            alt={title}
          />
        </div>
      )}
    </div>
  );
};

export default CertificationItem;
