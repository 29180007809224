import React from "react";

const CertificationSection = ({ title, description, children }) => (
  <div className="py-16 bg-gray-50 overflow-hidden">
    <div className="max-w-7xl mx-auto px-4 space-y-12 sm:px-6 lg:px-8">
      <div className="text-base max-w-prose mx-auto lg:max-w-none">
        <h2 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          {title}
        </h2>
        {description && (
          <div className="relative z-10 text-base max-w-prose mx-auto lg:max-w-5xl lg:mx-0 lg:pr-72">
            <p className="text-lg text-gray-500">{description}</p>
          </div>
        )}
      </div>
      {children}
    </div>
  </div>
);

export default CertificationSection;
